import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from "src/components/UI/Container"
import ShopItems from "src/components/shop/ShopItems"
import ShopItem from "src/components/shop/ShopItem"
import { Helmet } from "react-helmet"
import Header from "src/components/header/Header"
import Footer from "src/components/footer/Footer"
import "./shop.scss"

const shopItemsQuery = graphql`
  query {
    allShopItemsJson {
      edges {
        node {
          title
          description
          price
          id
          front
          back
          order
        }
      }
    }
    shopText: allWebsiteTextsJson(filter: { identifier: { eq: "shop" } }) {
      nodes {
        title
        text
        id
        shown
      }
    }
  }
`

export default () => {
  const {
    allShopItemsJson: { edges },
    shopText: {
      nodes: [shopText]
    }
  } = useStaticQuery(shopItemsQuery)

  const shopItems = useMemo(() => {
    return edges.map(({ node }) => ({ ...node }))
  }, [])

  return (
    <div className="page shop">
      <Header lightMode />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;900&display=swap"
          rel="stylesheet"
        />
        <title>BJJ Gent | Apparel</title>
      </Helmet>

      <Container className="shop-container offset-from-top">
        {shopText.shown ? (
          <div className="info-container">
            <h2 className="uppercase">{shopText.title}</h2>
            {shopText.text.split("\n").map(paragraph => (
              <p>{paragraph}</p>
            ))}
          </div>
        ) : (
          ""
        )}
        <ShopItems>
          {shopItems
            .sort((a, b) => a.order - b.order)
            .map(item => (
              <ShopItem key={item.id} item={item} />
            ))}
        </ShopItems>
      </Container>
      <Footer />
    </div>
  )
}
