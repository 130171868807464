import React, { useState } from 'react';
import { getURL } from 'src/components/util/url';
import './ShopItem.scss';

export default ({ item: { id, front, back, title, price, description } }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div className="shop-item">
      {
        <div
          className="item-placeholder"
          onMouseEnter={() => setIsHovered(true)}
          onTouchStart={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onTouchEnd={() => setIsHovered(false)}
        >
          {isHovered ? (
            <>
              {back ? (
                <img src={getURL(back)} alt={title} />
              ) : (
                <div className="item-placeholder empty">{title}</div>
              )}
            </>
          ) : (
            <>
              {front ? (
                <img src={getURL(front)} alt={title} />
              ) : (
                <div className="item-placeholder empty">{title}</div>
              )}
            </>
          )}
        </div>
      }
      <p className="item-title">{title}</p>
      <p className="item-price">{price}</p>
      <p className="item-description">{description}</p>
    </div>
  )
}
